import {
  Component,
  OnDestroy,
  OnInit,
  ErrorHandler,
  NgZone,
  computed,
} from '@angular/core';
import {mergeMap, Subscription} from 'rxjs';
import {ChooseProfileDialog} from './core/choose-profile-dialog/choose-profile-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {registerLocaleData} from '@angular/common';
import localeDanish from '@angular/common/locales/da';
import {LocaleService} from './core/services/locale/locale.service';
import {SubscriptionService} from './core/services/subscription/subscription.service';
import {App} from '@capacitor/app';
import {AuthService} from '@auth0/auth0-angular';
import {callbackUri} from './auth.config';
import {Browser} from '@capacitor/browser';
import {environment} from "../environments/environment";
import {ProfileService} from "./core/services/profile/profile.service";
import { EUserType } from './core/services/profile/user-type.enum';
import { Profile } from './core/services/profile/profile.data';
import {AppUpdateManager} from "./core/services/update-version/app-update-manager.service";
import { NavigationEnd, Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { LoadingService } from './core/services/loading/loading.service';

const SMALL_WIDTH_BREAKPOINT = 480;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isScreenSmall = false;

  isLoading = toSignal(this.loadingService.loading$, { initialValue: false });

  isActiveSubscription = computed(() => {
    const isActiveSubscription = this.subscriptionService.isActiveSubscription();

    if (this.subscriptionService.isActiveSubscription()) {
      if (!this.currentProfile()) {
        this.openChooseProfileDialog();
      }
    }
    // else the subscribed guard will take care of the correct redirect

    return isActiveSubscription;
  });

  currentProfile = this.profileService.currentProfile;
  theme = this.profileService.theme;
  isChildProfile = computed(() => {
    let isChildProfile = false;

    if (this.currentProfile() && (this.currentProfile()!.userType === EUserType.Kid || this.currentProfile()!.userType === EUserType.Tween)) {
      isChildProfile = true;
    }
    return isChildProfile;
  });

  private breakpointSub!: Subscription;

  constructor(
    private errorHandler: ErrorHandler,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private breakpointObserver: BreakpointObserver,
    private localeService: LocaleService,
    private subscriptionService: SubscriptionService,
    private ngZone: NgZone,
    private authService: AuthService,
    private appUpdateManager: AppUpdateManager,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.profileService.initNotifications();
    
    //subscribes every changes of your route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd){
        //scroll to top
        window.scrollTo(0,0);
      }
    });
  }

  ngOnInit(): void {

    // check version
    this.appUpdateManager.initialize().then(() => {
      // Optionally, you can add more logic here to test other functionalities.
    }).catch(error => {
      console.error('Failed to initialize AppUpdateManager', error);
    });

    // Handle callback from login
    this.handleLoginCallback();

    // get screen size
    this.breakpointSub = this.breakpointObserver.observe([`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`]).subscribe({
      next: (state: BreakpointState) => {
        this.isScreenSmall = state.matches;
      },
      error: (error) => this.errorHandler.handleError(error)
    });

    // Register locale data since only the en-US locale data comes with Angular
    registerLocaleData(localeDanish);
    this.localeService.setupLanguages();

    // Check notification permissions
    if (this.currentProfile()) {
      this.profileService.checkProfileNotificationPermission(this.currentProfile()!);
    }
  }

  ngOnDestroy(): void {
    this.breakpointSub.unsubscribe();
  }

  private openChooseProfileDialog(): void {
    const dialogRef = this.dialog.open(ChooseProfileDialog, {
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        const chosenProfile = result as Profile;
        this.profileService.setCurrentProfile(chosenProfile);
      }
    });
  }

  private handleLoginCallback() {
    // Use Capacitor's App plugin to subscribe to the `appUrlOpen` event
    App.addListener('appUrlOpen', ({url}) => {
      // Must run inside an NgZone for Angular to pick up the changes
      // https://capacitorjs.com/docs/guides/angular
      this.ngZone.run(() => {
        if (url?.startsWith(callbackUri)) {
          // If the URL is an authentication callback URL..
          if (
            url.includes('state=') &&
            (url.includes('error=') || url.includes('code='))
          ) {
            // Call handleRedirectCallback and close the browser
            this.authService.handleRedirectCallback(url).pipe(
              mergeMap(() => Browser.close())
            ).subscribe(next => {
              window.location.reload();
            });
          } else {
            Browser.close();
          }
        }
      });
    });
  }
}
