import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { ToDo } from "../../services/to-do/to-do.data";
import { FeatureFlagService } from "../../services/feature-flagging/feature-flagging.service";

@Component({
  selector: 'app-tween-to-do-toggle',
  templateUrl: './tween-to-do-toggle.component.html',
  styleUrls: ['./tween-to-do-toggle.component.scss']
})
export class TweenToDoToggleComponent {
  @Input() toDos: ToDo[] = [];
  @Output() toDoClickedEvent = new EventEmitter<ToDo>();

  faChevronDown = faChevronDown;
  featureFlagList = this.featureFlagService.featureFlagList;

  arrowDown = false;
  hideToDos  = true;

  constructor(
    private featureFlagService: FeatureFlagService,
  ) { }

  toggleDisplayToDos() {
    this.hideToDos = !this.hideToDos;
    this.arrowDown = !this.arrowDown;
  }

  onToDoClicked(toDo: ToDo): void {
    this.toDoClickedEvent.emit(toDo);
  }
}
