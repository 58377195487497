import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscribedGuard } from './core/guards/subscribed.guard';
import { HomePageComponent } from './core/home-page/home-page.component';
import { AdminGuard } from './core/guards/admin.guard';
import { UniversePageComponent } from './core/universe-page/universe-page.component';
import { Auth0Guard } from './core/guards/auth0.guard';
import { LoginPageComponent } from './core/login-page/login-page.component';
import { ErrorPageComponent } from './core/error-page/error-page.component';

const routes: Routes = [
  {
    path: 'admin',
    canLoad: [ Auth0Guard, SubscribedGuard, AdminGuard ],
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule),
  },
  {
    path: 'calendar',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarModule),
  },
  {
    path: 'catalog',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./catalog/catalog.module').then( m => m.CatalogModule),
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: 'feedback',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackModule)
  },
  {
    path: 'kids',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./kids/kids.module').then( m => m.KidsModule)
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'reward',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./reward/reward.module').then( m => m.RewardModule)
  },
  {
    path: 'scoreboard',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./scoreboard/scoreboard.module').then( m => m.ScoreboardModule)
  },
  {
    path: 'setting',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingModule)
  },
  {
    path: 'subscription',
    canLoad: [ Auth0Guard ],
    loadChildren: () => import('./subscription/subscription.module').then( m => m.SubscriptionModule)
  },
  {
    path: 'summer',
    canLoad: [ Auth0Guard ],
    loadChildren: () => import('./summer/summer.module').then( m => m.SummerModule)
  },
  {
    path: 'halloween',
    canLoad: [ Auth0Guard ],
    loadChildren: () => import('./halloween/halloween.module').then( m => m.HalloweenModule)
  },
  {
    path: 'to-do',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./to-do/to-do.module').then( m => m.ToDoModule)
  },
  {
    path: 'tween',
    canLoad: [ Auth0Guard, SubscribedGuard ],
    loadChildren: () => import('./tween/tween.module').then( m => m.TweenModule)
  },
  {
    path: 'universes',
    canActivate: [ Auth0Guard, SubscribedGuard ],
    component: UniversePageComponent
  },
  {
    path: 'home',
    canActivate: [ Auth0Guard, SubscribedGuard ],
    component: HomePageComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
