@if (toDos.length) {
  <div class="to-do-frame">
    @if (featureFlagList() && featureFlagList()!.halloween) {
      <img class="web-img" src="../../../../assets/images/halloween-wheel/web4.png" alt="" height="80px" height="auto">
      }
    <!-- Visible to-dos -->
    <app-tween-to-do
      [toDo]="toDos[0]"
      (toDoClickedEvent)="onToDoClicked(toDos[0])"
    ></app-tween-to-do>

    @if (toDos && toDos.length > 1) {
      <app-tween-to-do
        [toDo]="toDos[1]"
        (toDoClickedEvent)="onToDoClicked(toDos[1])"
      ></app-tween-to-do>
    }
    
    <!-- Hidden to-dos -->
    @if (toDos.length > 2) {
      <span [hidden]="hideToDos">
        @if (!hideToDos) {
          @for (toDo of toDos | slice:2; track toDo.id) {
            <app-tween-to-do
              [toDo]="toDo"
              (toDoClickedEvent)="onToDoClicked(toDo)"
            ></app-tween-to-do>
          }
        }
      </span>
      <!-- Toggle arrow -->
      <div class="rotate" [class.down]="arrowDown">
        <button aria-label="Icon-button with arrow back icon" (click)="toggleDisplayToDos()">
          <fa-icon style="color: yellow;" [icon]="faChevronDown" size="lg"></fa-icon>
        </button>
      </div>
    }
  </div>
}

