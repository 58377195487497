<section class="container-events">
  @for (event of events; track event.id) {
    
  <div class="container-card" (click)="navigateCalEvent(event)">

    @if (event.imageStr) {
    <img class="background-image" [src]="event.imageStr" alt="">
    }

    @else {
    <img class="background-image" src="assets/images/kids/childrens-event.png" alt="">
    }

    <!-- <div [ngStyle]="{'background-image': event.imageStr ? 'url(\''+event.imageStr+'\')' : 'url(\'../../../../assets/images/kids/childrens-event.png\')'}"></div> -->
    <div class="footer"><p>{{ event.name }}</p></div>
  </div>
  }
</section>