import { Component, Input } from '@angular/core';
import { FeatureFlagService } from '../../services/feature-flagging/feature-flagging.service';

@Component({
  selector: 'app-child-greeting',
  templateUrl: './child-greeting.component.html',
  styleUrls: ['./child-greeting.component.scss']
})
export class ChildGreetingComponent {
  @Input() greeting: string | null = null;
  @Input() greetingText: string | null = null;
  featureFlagList = this.featureFlagService.featureFlagList;
  witchAudio = new Audio();


  constructor(
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.startBookAnimation();
    this.startWitchAnimation();
  }

  ngAfterViewInit(): void {
    if (this.featureFlagList() && this.featureFlagList()!.halloween) {
      /* ----------- Start audio when page loads ------------ */
      this.witchAudio.src = "../../../../assets/sounds/witch.mp3";
      this.witchAudio.load();
      this.startWitchAnimation();
    }
  }

  startWitchAnimation() {
    if (this.featureFlagList() && this.featureFlagList()!.halloween) {
      const myWitch = <HTMLElement>document.getElementById('animatedWitch');
      myWitch.addEventListener("click", (e) => {
        e.preventDefault();

            /* ----------- Start audio when page loads ------------ */
          
            this.witchAudio.play();

        //adding animate class to button
        myWitch.classList.add("witchanimate");

        setTimeout(() => {
          //removing animate class after 600ms
          myWitch.classList.remove("witchanimate");
        }, 600);
      });
    }
  }

  startBookAnimation() {
    const myWheel = <HTMLElement>document.getElementById('animatedBook');
    myWheel.addEventListener("click", (e) => {
      e.preventDefault();

      // https://www.youtube.com/watch?v=XGBEM8oWoCI
      let audio = new Audio();
      audio.src = "../../../../assets/sounds/soundbook/pageturn.mp3";
      audio.load();
      audio.play()

      //adding animate class to button
      myWheel.classList.add("bookanimate");

      setTimeout(() => {
        //removing animate class after 600ms
        myWheel.classList.remove("bookanimate");
      }, 600);
    });
  }
}
