<div class="margin-wrapper">
    <div class="container" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; margin-top: 0.5rem;">
        <div class="title-container">
            <span class="title">{{ 'halloween-campaign.title' | translate }}</span>
            <span class="subtitle">{{ 'halloween-campaign.subtitle' | translate }}</span>
        </div>
  
        <img class="pumpkin-img" src="../../../../assets/images/halloween-wheel/pumpkin.png" alt="">
  
        <section class="btn-section">
            <div class="btn-and-lottie" style="position: relative;">

                <ng-lottie [options]="spider" width="70px" height="200px" style="position: absolute; top: -100px; left: 0; z-index: 50;" />

                <button style="height: 50px; width: 100px; border-radius: 30px;" class="halloween-btn" (click)="navigateHalloweenWheel()">
                    {{ 'halloween-campaign.spin' | translate }}
                    <img class="spider-web" src="../../../../assets/images/halloween-wheel/web.png" width="80px" height="auto" alt="">
                </button>
            </div>
        </section>
    </div>
</div>