<div class="greetingContainer">
  <div class="bookBox" id="animatedBook"></div>
<div class="greetingBox">
  <div class="greetingTop"></div>
    <div class="greetingMiddle">
      <p class="body-text">{{ greeting }}</p>
      <p class="body-text-secondary">{{ 'kids-home.name-greeting-text' | translate }}</p>
    </div>
    <div class="greetingBottom"></div>
  </div>

  @if (featureFlagList() && featureFlagList()!.halloween) {
  <div class="maskotBoxHalloween" id="animatedWitch" (click)="startWitchAnimation()"></div>
  }
  @else {
    <div class="maskotBox"></div>
  }
</div>
