<!-- <app-header [title]="'login.title' | translate" [subtitle]="' '"></app-header> -->

<div class="page-wrapper">

  <div class="flex-column">
    <!-- Introduction -->
    <h2>{{ 'login.textOne' | translate }}</h2>

    <p>
      <span>{{ 'login.textTwo' | translate }}</span>
    </p>

    <!-- Login button -->
    <div class="button-container">

      <button class="subscription-btn" (click)="login()">
        {{ 'login.login' | translate }}
      </button>
    </div>

    <!-- Image -->
    <img class="img" src="./assets/images/login-splashscreen.png" alt="Drawing of tricks waving and the Family Tricks logo">
  </div>

</div>