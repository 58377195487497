@if (currentProfile()) {
  @switch (currentProfile()!.userType) {
    <!-- Adult home -->
    @case (userType.Adult) {
      <app-adult-home></app-adult-home>
    }
    <!-- Kid home -->
    @case (userType.Kid) {
      <app-kids-home></app-kids-home>
    }
    <!-- Tween home -->
    @case (userType.Tween) {
      <app-tween-home></app-tween-home>
    }
  }
}