<div class="white-backkground">
  <app-header [title]="'error-page.title' | translate" [subtitle]="'error-page.subtitle' | translate"></app-header>

  <div class="page-wrapper">
    <div class="card">
      <!-- Introduction -->
      <h2>{{ 'error-page.textOne' | translate }}</h2>

      <h3>{{ 'error-page.textTwo' | translate }}</h3>

      <!-- Logout button -->
      <div class="button-container">
        <button class="logout-btn" (click)="logout()">
         {{ 'error-page.logout' | translate }}
        </button>
      </div>

      <!-- Contact us -->
      <h3>{{ 'error-page.textThree' | translate }}</h3>

      <!-- Contact button -->
      <div class="button-container">
        <a class="contact-btn" [href]="contactLink" target="_blank">
          {{ 'error-page.contact-us' | translate }}
        </a>
      </div>
    </div>
  </div>

</div>