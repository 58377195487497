import { Component } from '@angular/core';
import { LogoutService } from '../services/logout/logout.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

  contactLink: string = 'https://family-tricks.dk/kontakt-os/?_gl=1*1mdd8uq*_up*MQ..*_ga*MTM1NzE5NzA2OC4xNzA0NzIzNjcz*_ga_JVCBG4R9MQ*MTcwNDcyMzY3My4xLjAuMTcwNDcyMzY3My4wLjAuMA..*_ga_TVBLV29V83*MTcwNDcyMzY3My4xLjAuMTcwNDcyMzY3My4wLjAuMA..';

  constructor(
    public logoutService: LogoutService
  ) { }

  logout() {
    this.logoutService.logout();
  }
}
