<mat-toolbar color="primary" class="bottom-sheet-title">
  <span>{{ 'add-to-do-sheet.title' | translate }}</span>
</mat-toolbar>

<div class="page-wrap">
  @if (form) {
  <form [formGroup]="form">

    <div class="form-wrapper">
      <!-- Name -->
      <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
        <mat-label>{{ 'add-to-do-sheet.name' | translate }}</mat-label>
        <input matInput formControlName="name" required>
        @if (form.controls.name.touched && form.controls.name.invalid && form.controls.name.errors?.['required']) {
        <mat-error>
          <span>{{ 'add-to-do-sheet.required' | translate }}</span>
        </mat-error>
        }
      </mat-form-field>

      <!-- Start date -->
      <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
        <mat-label>{{ 'add-to-do.date' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" readonly formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix (click)="clearStartDate()">
          <mat-icon matDatepickerToggleIcon><fa-icon [icon]="faXmark"></fa-icon></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>

            <!-- Start time -->
      <mat-form-field class="time" appearance="outline" color="accent" subscriptSizing="dynamic">
      <mat-label>{{ 'add-to-do.start-time' | translate }}</mat-label>
      <input type="time" matInput formControlName="startTime" placeholder="{{ 'add-to-do.start-time' | translate }}">
    </mat-form-field>

      <!-- Assign to -->
      <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
        <mat-label>{{ 'add-to-do.who' | translate }}</mat-label>
        <mat-select formControlName="subscriberIds" multiple>
          <mat-select-trigger>
            {{ form.controls.subscriberIds.value | selectedProfile: model.profiles }}
            @if (form.controls.subscriberIds.value && form.controls.subscriberIds.value.length > 1) {
            <span class="additional-selection">
              (+{{form.controls.subscriberIds.value.length - 1}} {{form.controls.subscriberIds.value?.length === 2
              ? ('add-to-do.other' | translate) : ('add-to-do.others' | translate)}})
            </span>
            }
          </mat-select-trigger>
          @for (profile of model.profiles; track profile.id) {
          <mat-option [value]="profile.id">{{ profile.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  }

  <!-- Save button -->
  <div class="btn-wrapper">
    <app-save-button [text]="'add-to-do-sheet.save-task' | translate" [disabled]="!form.valid" (saveEvent)="save()">
    </app-save-button>
  </div>
  <br>
</div>