import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import { browserTracingIntegration, init } from '@sentry/angular';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

init({
  dsn: environment.sentry.dsn,
  integrations: [browserTracingIntegration()],
  tracePropagationTargets: environment.sentry.tracePropagationTargets,
  tracesSampleRate: environment.sentry.tracesSampleRate,
  replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
  

});


const bootstrap = () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
};
const setup = (override: boolean = false) => {

  if (override) {
    bootstrap();
    return;
  }

  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  let isMobile = regex.test(navigator.userAgent);
  if (isMobile) {
    document.addEventListener('deviceready', bootstrap, false);

  } else {
    bootstrap();
  }
};

if (environment.environment === 'production') {
  if (environment.production) {
    enableProdMode();
    setup();
  }
} else if (environment.environment === 'staging') {
  if (environment.production) {
    enableProdMode();
    setup();
  }
} else if (environment.environment === 'development') {
  setup(true);
}

