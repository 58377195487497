import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {Observable, catchError, of, switchMap, take} from 'rxjs';
import { SubscriptionService } from '../services/subscription/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SubscribedGuard  {

  constructor(
    private router: Router,
    private subscriptionService: SubscriptionService
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.subscribed();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.subscribed();
  }


  private subscribed(): Observable<boolean | UrlTree> {
    return this.subscriptionService.getSubscription().pipe(
      take(1),
      switchMap(subscription => {
        if (!subscription) {
          return of(this.router.parseUrl('/subscription/add'));
        } else if (subscription && subscription.activeSubscription === true)
        {
          return of(true);
        }
        return of(this.router.parseUrl('/subscription/choose'));
      }),
      catchError(err => {
        if (err.message.includes("Missing Refresh Token")) {
          return of(this.router.parseUrl('/login'));
        } else {
          return of(this.router.parseUrl('/error'));
        }
      })
    );
  }
}
