import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToDo } from '../../services/to-do/to-do.data';
import { ESmartLinkType } from '../../services/to-do/smart-link-type.enum';

@Component({
  selector: 'app-tween-to-do',
  templateUrl: './tween-to-do.component.html',
  styleUrls: ['./tween-to-do.component.scss']
})
export class TweenToDoComponent {
  @Input() toDo!: ToDo;
  @Output() toDoClickedEvent = new EventEmitter();

  ESmartLinkType = ESmartLinkType;

  onToDoClicked(): void {
    this.toDoClickedEvent.emit();
  }
}
