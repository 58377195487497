<mat-card class="container" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; margin-top: 2rem;">
  <mat-card-content style="padding: 0;">
    <div class="center">
      <img class="campaign-img" src="assets/images/summer-wheel/campaign.png" alt="Kampagne">

      <div class="title-container">
        <span class="title">{{ 'summer-campaign.title' | translate }}</span>
        <span class="subtitle">{{ 'summer-campaign.subtitle' | translate }}</span>
      </div>

      <img class="sun-img" src="assets/images/summer-wheel/pink-sun.png" alt="">
    </div>

    <section class="text-wrapper">
      <p class="body-text">{{ 'summer-campaign.text-1' | translate }}</p>
      <p class="body-text">{{ 'summer-campaign.text-2' | translate }}</p>
      <p class="body-text">{{ 'summer-campaign.text-3' | translate }}</p>
    </section>

    <section class="btn-section">
    <button style="height: 50px; width: 100px; border-radius: 30px;" mat-raised-button class="pink-btn" (click)="navigateSummerWheel()">{{ 'summer-campaign.spin' | translate
      }}</button>
    </section>
  </mat-card-content>
</mat-card>