import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { OverlayContainer } from '@angular/cdk/overlay';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { ToDo } from '../../services/to-do/to-do.data';
import { AddToDoSheetModel } from './add-to-do-sheet.model';
import { TicksService } from '../../services/ticks/ticks.service';
import { EToDoType } from '../../services/to-do/to-do-type.enum';
import { ToDoTask } from '../../services/to-do/to-do-task.data';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../services/profile/profile.service';

@Component({
  selector: 'app-add-to-do-sheet',
  templateUrl: './add-to-do-sheet.component.html',
  styleUrls: ['./add-to-do-sheet.component.scss']
})
export class AddToDoSheet implements OnInit {

  faXmark = faXmark;
  form!: UntypedFormGroup;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public model: AddToDoSheetModel,
    private fb: UntypedFormBuilder,
    private overlayContainer: OverlayContainer,
    private profileService: ProfileService,
    private bottomSheetRef: MatBottomSheetRef<AddToDoSheet>,
    private ticksService: TicksService,
    private dateAdapter: DateAdapter<any>,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      subscriberIds: [],
      startDate: new Date(),
      startTime: null
    });

    this.form.patchValue({
      subscriberIds: [ this.model.currentProfileId ]  
    });

    this.overlayContainer.getContainerElement().classList.remove(`${this.profileService.alternativeTheme()}`);
    this.overlayContainer.getContainerElement().classList.add(`${this.profileService.theme()}`);

    this.dateAdapter.setLocale(this.translateService.currentLang);
  }

  clearStartDate() {
    this.form.patchValue({
      startDate: null
    });
  }

  save(): void {
    const name = this.form.controls.name.value;
    const subscriberIds = this.form.controls.subscriberIds.value;


     // start time
     let startHour: number | null = null;
     let startMinute: number | null = null;
     if (this.form.controls.startTime.value) {
      const startTimeArr = this.form.controls.startTime.value.split(':');
      startHour = Number(startTimeArr[0]);
      startMinute = Number(startTimeArr[1]);
    } 

    let startDate: number | null = null;
    if (this.form.controls.startDate.value) {

      startDate = this.ticksService.getTicks(this.form.controls.startDate.value);
    }

    let newToDo: ToDo  = {
      id: -1,
      name: null,
      description: null,
      subscriberIds: subscriberIds,
      imageStr: null,
      initialCatalogId: null,
      start: startDate,
      recurrencePattern: null,
      type: EToDoType.Single,
      tasks: [],
      next: null,
      done: false,
      expanded: false,
      recurrenceDone: false,
      showAsRecurrent: false,
      startHour: null,
      startMinute: null
    };

    const newTask: ToDoTask = {
      id: -1,
      name: name,
      description: null,
      imageStr: null,
      done: false,
      orderNo: 0,
      startHour: startHour,
      startMinute: startMinute,
      durationInSecs: null,
      parentId: null,
      toDoId: -1,
      subTasks: [],
      expanded: false,
      smartTask: null
    };
    newToDo.tasks.push(newTask);

    this.bottomSheetRef.dismiss(newToDo);
  }
}
