<app-header [title]="'adult-home.name-greeting' | translate : { name: currentProfile()!.name }"></app-header>

<!-- <div class="body-wrapper"> -->
@if (toDoError() || eventError() || countdownError() || featureFlagListError()) {
<div class="margin-wrapper">
  <app-error-img></app-error-img>
</div>
}
<!-- ----------- Today's ------------ -->
<section class="section">

  <!-- ------------------ Tasks ---------------- -->

  <div class="title-card-container">
    <!-- Header -->
    <div class="titel-btn-container padding">
      <h2>{{ 'adult-home.todays-tasks' | translate }}</h2>

      <button mat-icon-button color="accent" (click)="addTask()" class="add-btn">
        <fa-icon style="color: #b2c0b2 !important;" [icon]="fasquareplus" size="lg"></fa-icon>
      </button>
    </div>

    <!-- Tasks -->
    @if (myToDosToDay() && myToDosToDay().length) {
    <app-nested-to-dos-toggle [toDos]="myToDosToDay()" (taskToggledEvent)="onTaskToggled($event)"
      (toDoToggledEvent)="onToDoToggled($event)"></app-nested-to-dos-toggle>
    }
  </div>


  <!-- ------------------ Calendar ---------------- -->
  <div class="title-card-container">
    <!-- Header -->
    <div class="titel-btn-container padding">
      <h2>{{ 'adult-home.todays-events' | translate }}</h2>

      <a mat-icon-button style="color: #b2c0b2 !important;" [routerLink]="'/calendar/events/add'" class="add-btn">
        <fa-icon [icon]="fasquareplus" size="lg"></fa-icon>
      </a>
    </div>

    <!-- Calendar events -->
    @if (myEventsToDay() && myEventsToDay().length > 0) {
    <div style="margin: 0 24px;">
      <app-cal-events [events]="myEventsToDay()"></app-cal-events>
    </div>
    }
  </div>


  <!-- Campaign -->
  @if (!hideNews() && featureFlagList()) {

    @if (featureFlagList()!.schoolStart) {
      <!-- Shortcut to adding a timetables for a kid profile -->
      <app-school-start-campaign
        (hideNewsOnHomePageEvent)="hideNewsOnHomePage()"
        (navigateAddTimetableEvent)="navigateAddTimetable()"
      ></app-school-start-campaign>
    }
    
    @if (featureFlagList()!.summer) {
      <!-- Summer campaign -->
      <app-summer-campaign></app-summer-campaign>
    }

    <!-- <app-summer-campaign></app-summer-campaign> -->


    @if (featureFlagList() && featureFlagList()!.halloween) {
      <!-- Halloween campaign -->
      <app-halloween-campaign (navigateHalloweenWheelEvent)="navigateHalloweenWheel()"></app-halloween-campaign>
    }
  }

  <!-- ------------------ Countdowns ---------------- -->

  @if (myCountdowns() && myCountdowns().length > 0) {
  <div class="title-card-container">
    <!-- Header -->
    <div class="titel-btn-container padding" style="padding-bottom: 4px;">
      <h2>{{ 'adult-home.countdowns' | translate }}</h2>
    </div>

    <!-- countdowns in a list -->
    <app-countdown-image-slider [imageSlides]="myCountdowns()"></app-countdown-image-slider>
  </div>
  }

  <div style="margin: 0 24px;">
    <app-find-inspiration [text]="'to-do-page.find-inspiration' | translate"
      (navigateInspirationEvent)="navigateCatalog()"></app-find-inspiration>
  </div>
</section>