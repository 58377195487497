<section class="timetables-card">
    <div class="close-wrapper" (click)="hideNewsOnHomePage()">
      <fa-icon [icon]="faCircleXMark" size="2xl" style=" color: #fff;"></fa-icon>
    </div>

    <img class="pencil" src="../../../../assets/images/timetable/pencil.png" alt="" height="auto" width="30px">

    <img class="bag-img" src="../../../../assets/images/timetable/school-bag.png" alt="" height="auto" width="80px">

    <p class="card-title">{{ 'add-timetable.title' | translate }}</p>
    <p class="card-text">{{ 'add-timetable.card-text' | translate }}</p>
    <fam-button-cta [btnName]="'add-timetable.add-timetable' | translate"
      (buttonClickedEvent)="navigateAddTimetable()"></fam-button-cta>
</section>