import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProfileService } from '../services/profile/profile.service';
import { EUserType } from '../services/profile/user-type.enum';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent {

  userType = EUserType;
  currentProfile = this.profileService.currentProfile;

  constructor(
    private profileService: ProfileService
  ) {}
}
