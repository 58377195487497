
<div class="container">
  <mat-card>
    <mat-card-header>
      <h2>{{ title }}</h2>
    </mat-card-header>
      <img class="mat-card-image img" src="./assets/images/undraw_relaxation.svg" alt="Image of woman lying on a blanket under a parasol">
  </mat-card>
</div>

