import { Component, EventEmitter, Output } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-halloween-campaign',
  templateUrl: './halloween-campaign.component.html',
  styleUrl: './halloween-campaign.component.scss'
})
export class HalloweenCampaignComponent {
  @Output() navigateHalloweenWheelEvent = new EventEmitter();

  spider: AnimationOptions = {
    path: '/assets/lottie/spider.json',
  };

  navigateHalloweenWheel() {
    this.navigateHalloweenWheelEvent.emit();
  }
}
