<!-- Greeting -->
<app-child-greeting
  [greeting]="'tween-home.name-greeting' | translate : { name: currentProfile()!.name }"></app-child-greeting>

<div class="page-wrapper-no-sidemargin">

  @if (toDoError() || eventError() || countdownError() || wheelError() || rewardError()) {
  <div class="margin-wrapper">
    <app-error-img></app-error-img>
  </div>
  }

  <section class="list-of-sections">
    <!-- To Dos -->
    @if (myToDosToDay()) {
    <section>
      @if (myToDosToDay().length) {
      <section class="section-wrapper">
        <h2>{{ 'tween-home.your-tasks' | translate }}</h2>
        <app-tween-to-do-toggle [toDos]="myToDosToDay()"
          (toDoClickedEvent)="navigateToDo($event)"></app-tween-to-do-toggle>
      </section>
      } @else {
      <!-- Image when done -->
      <section class="section-wrapper">
        <h2>{{ 'tween-home.your-tasks' | translate }}</h2>
        <app-done-img [title]="'tween-home.done-img-title' | translate"></app-done-img>
      </section>
      }
    </section>
    }

    @if (featureFlagList() && featureFlagList()!.halloween) {
      <img (click)="startMummyAnimation()" class="mummy" id="animatedMummy" src="../../../../assets/images/halloween-wheel/mummy2.png" alt="" width="90px" height="auto">
      }

    <!-- Events -->
    @if (myEventsToDay() && myEventsToDay().length > 0) {
    <h2 style="margin-left: 24px; margin-top: 24px;">{{ 'kids-home.your-events' | translate }}</h2>
    <div>
      <app-childrens-events [events]="myEventsToDay()"></app-childrens-events>
    </div>
    }

    <!-- Wheel of fortune -->
    @if (myWheel() && myWheel()!.rewardIds && myWheel()!.rewardIds.length > 0) {
    <section class="section-wrapper" style="margin-top: 2.5rem">
      <h2>{{ 'tween-home.rewards' | translate }}</h2>
      <app-wheel-of-fortune-score [wheel]="myWheel()!"></app-wheel-of-fortune-score>
    </section>
    }

    <!-- Reward waiting -->
    @if (rewardToDo(); as rewardToDo) {
    <div class="reward-text-box-wrapper">
      <div class="btn-wrap" (click)="navigateRewardDialog('600ms', '600ms')">
        <button class="waiting-reward-btn">
          {{ 'tween-home.reward-waiting' | translate }}
          <img class="star-animation" src="../../../../assets/images/kids/star.png" alt="Star that sparkles">
        </button>

      </div>
    </div>
    }

    @if (featureFlagList() && featureFlagList()!.halloween) {
          <!-- Halloween edition -->
    <ng-lottie (click)="play()" [options]="ghost" width="80px" height="80px" (animationCreated)="animationCreated($event)" 
    style="display: flex; width: 100%; max-width: 100%; justify-content: center; margin-top: 16px; margin-bottom: 16px; filter: drop-shadow(3.5px 3.5px 3.5px #aaaaaa);"/>
    }

    <!-- Countdowns -->
    @if (myCountdowns() && myCountdowns().length > 0) {
      @if (featureFlagList() && featureFlagList()!.halloween) {
        <section class="section-wrapper-halloween">
          <h2 class="padding">{{ 'tween-home.countdowns' | translate }}</h2>
          <app-kids-countdown-image-slider [imageSlides]="myCountdowns()"></app-kids-countdown-image-slider>
        </section>
      }
      @else {
        <section class="section-wrapper-countdown">
          <h2 class="padding">{{ 'tween-home.countdowns' | translate }}</h2>
          <app-kids-countdown-image-slider [imageSlides]="myCountdowns()"></app-kids-countdown-image-slider>
        </section>
      }
    }
  </section>
</div>

<!-- Navigation -->
<app-child-navigation></app-child-navigation>