import { Component } from '@angular/core';
import { Activity } from 'src/app/core/services/catalog/activity.data';
import { MatDialog } from '@angular/material/dialog';
import { SummerWheelPageComponent } from 'src/app/summer/summer-wheel-page/summer-wheel-page.component';

@Component({
  selector: 'app-summer-campaign',
  templateUrl: './summer-campaign.component.html',
  styleUrl: './summer-campaign.component.scss'
})
export class SummerCampaignComponent {

  wonActity: Activity | null = null;

  constructor(
    private dialog: MatDialog
  ) {}

  navigateSummerWheel() {
    this.dialog.open(SummerWheelPageComponent, {
      data: this.wonActity,
      panelClass: 'app-full-bleed-dialog2',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });
  }
}
