import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KidsCalEventDialogComponent } from 'src/app/shared-core/components/kids-cal-event-dialog/kids-cal-event-dialog.component';
import { CalEvent } from '../../services/calendar/cal-event.data';


@Component({
  selector: 'app-childrens-events',
  templateUrl: './childrens-events.component.html',
  styleUrls: ['./childrens-events.component.scss']
})
export class ChildrensEventsComponent {
  @Input() events: CalEvent[] = [];

  constructor(
    private dialog: MatDialog
  ) {}

  navigateCalEvent(event: CalEvent) {
    this.dialog.open(KidsCalEventDialogComponent, {
      data: event,
      panelClass: 'kid-calendar-dialog',
      minHeight: '100px',
      width: '90%',
      maxWidth: '480px',
      autoFocus: false
    });
  }
}
