import { Component, EventEmitter, Output } from '@angular/core';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-school-start-campaign',
  templateUrl: './school-start-campaign.component.html',
  styleUrl: './school-start-campaign.component.scss'
})
export class SchoolStartCampaignComponent {
  @Output() hideNewsOnHomePageEvent = new EventEmitter();
  @Output() navigateAddTimetableEvent = new EventEmitter();

  faCircleXMark = faCircleXmark;

  hideNewsOnHomePage() {
    this.hideNewsOnHomePageEvent.emit();
  }

  navigateAddTimetable() {
    this.navigateAddTimetableEvent.emit();
  }
}
