import { Component, computed } from '@angular/core';
import { Router } from '@angular/router';
import { UniverseService } from '../services/universe/universe.service';
import { EUniverse } from 'src/app/shared/enums/universe.enum';
import { MatDialog } from '@angular/material/dialog';
import { SummerWheelPageComponent } from 'src/app/summer/summer-wheel-page/summer-wheel-page.component';
import { HalloweenGreetingDialogComponent } from 'src/app/halloween/halloween-greeting-dialog/halloween-greeting-dialog.component';
import { FeatureFlagService } from '../services/feature-flagging/feature-flagging.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-universe-page',
  templateUrl: './universe-page.component.html',
  styleUrls: ['./universe-page.component.scss']
})
export class UniversePageComponent {

  public universe = EUniverse;
  featureFlagList = this.featureFlagService.featureFlagList;


  constructor(
    private router: Router,
    private universeService: UniverseService,
    private dialog: MatDialog,
    private featureFlagService: FeatureFlagService,
    private authService: AuthService,
  ) {}

  private authServiceUser = toSignal(this.authService.user$);
  isAdmin = computed(() => {
    let isAdmin: boolean = false;

    if (this.authServiceUser()) {
      const roles = this.authServiceUser()!["https://family-tricks.com/roles"] as string[];
      if (roles && roles.length > 0) {
        isAdmin = roles.some(role => role === 'FamilyTricksTeamMember');
      }
    }
    return isAdmin;
  });

  setUniverse(newUniverse: string) {
    this.universeService.setUniverse(newUniverse);

    switch (newUniverse) {
      case 'to-do': {
        this.navigateToDo();
        break;
      }
      case 'reward': {
        this.navigateReward();
        break;
      }
      case 'catalog': {
        this.navigateCatalog();
        break;
      }
      case 'admin': {
        this.navigateAdmin();
        break;
      }
      case 'calendar': {
        this.navigateCalendar();
        break;
      }
      case 'scoreboard': {
        this.navigateScoreboard();
        break;
      }
      case 'home':
      default: {
        this.navigateHome();
        break;
      }
    }
  }

  navigateSummerWheelPage() {
    this.dialog.open(SummerWheelPageComponent, {
      panelClass: 'app-full-bleed-dialog2',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
    });

  }

  navigateHalloweenWheelPage() {
    this.dialog.open(HalloweenGreetingDialogComponent, {
      panelClass: 'halloween-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
    });
  }


  private navigateToDo() {
    this.router.navigateByUrl(`to-do`);
  }

  private navigateReward() {
    this.router.navigateByUrl(`reward`);
  }

  private navigateCatalog() {
    this.router.navigateByUrl(`catalog`);
  }

  private navigateAdmin() {
    this.router.navigateByUrl(`admin`);
  }

  private navigateCalendar() {
    this.router.navigateByUrl(`calendar`);
  }

  private navigateScoreboard() {
    this.router.navigateByUrl(`scoreboard`);
  }

  private navigateHome() {
    this.router.navigateByUrl(`home`);
  }
}
