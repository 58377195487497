<app-header [showPage]="true" [title]="'universe-page.title' | translate"></app-header>

<div class="page-wrapper">

  <!-- List of universe elements -->
  <ul>
    <!-- Catalog -->
    <li>
      <a class="universe-container" (click)="setUniverse(universe.Catalog)">
        <p class="universe-title">{{ 'universe-page.catalog' | translate }}</p>
        <img style="padding-right: 18px; max-width: 85px; height: auto;"
          src="../../../assets/images/universes/catalog.png" width="85px" height="auto">
      </a>
    </li>

    <!-- To Do -->
    <li>
      <a class="universe-container" (click)="setUniverse(universe.ToDo)">
        <p class="universe-title">{{ 'universe-page.todo' | translate }}</p>
        <img style="padding-right: 18px; max-width: 85px; height: auto;" src="../../../assets/images/universes/todo.png"
          width="85px" height="auto">
      </a>
    </li>

    <!-- Calendar -->
    <li>
      <a class="universe-container" (click)="setUniverse(universe.Calendar)">
        <p class="universe-title">{{ 'universe-page.calendar' | translate }}</p>
        <img style="padding-right: 18px; max-width: 85px; height: auto;" class="padding-right"
          src="../../../assets/images/universes/calendar.png" width="85px" height="auto">
      </a>
    </li>

    <!-- Rewards -->
    <li>
      <a class="universe-container" (click)="setUniverse(universe.Reward)">
        <p class="universe-title">{{ 'universe-page.reward' | translate }}</p>
        <img style="padding-right: 18px; max-width: 85px; height: auto;"
          src="../../../assets/images/universes/reward.png" width="85px" height="auto">
      </a>
    </li>

    <!-- Summer -->
    <!-- <li>
      <a class="universe-container summer-bg" (click)="navigateSummerWheelPage()">
        <img class="wheel-img" src="assets/images/summer-wheel/campaign.png">
        <div class="summer-container">
          <p class="summer-title">{{ 'universe-page.summer' | translate }}</p>
          <p class="summer-body">{{ 'universe-page.summer-subtitle-1' | translate }}</p>
          <p class="summer-subtitle">{{ 'universe-page.summer-subtitle-2' | translate }}</p>
        </div>
        <img class="sun-img" src="assets/images/summer-wheel/pink-sun.png">
      </a>
    </li> -->

    <!-- Halloween -->
    @if (featureFlagList() && featureFlagList()!.halloween) {
    <li>
      <a class="universe-container halloween-bg" (click)="navigateHalloweenWheelPage()">
        <div class="halloween-container">
          <p class="halloween-title">{{ 'halloween-campaign.button' | translate }}</p>
        </div>
        <img class="halloween-img" src="../../../assets/images/halloween-wheel/trio.png">
      </a>
    </li>
  }



    <!-- Scoreboard -->
    @if (false) {
    <li class="universe-container" (click)="setUniverse(universe.Scoreboard)">
      <img
        src="https://daitaskstorage.blob.core.windows.net/auth06394724404dc50e3417876df/Ribbon%20-%20kampange_66246e88-b307-4eb9-abaa-4d99205152cb.png"
        width="85px" height="auto">
      <div class="womens-battle-container">
        <p class="womens-battle-title">{{ 'universe-page.scoreboard' | translate }}</p>
        <p class="womens-battle-subtitle">{{ 'universe-page.scoreboard-subtitle' | translate }}</p>
      </div>
      <img style="padding-right: 18px; max-width: 85px; height: auto;"
        src="https://daitaskstorage.blob.core.windows.net/auth06394724404dc50e3417876df/Hvid%20-%20battle%20ikon%20med%20begge%20elementer_a1cebdde-f2a5-440d-90c1-24acec5382b5.png">
    </li>
    }

    <!-- Food -->
    <li (click)="isAdmin()? navigateHalloweenWheelPage() : '' ">
      <a class="universe-container">
        <div class="coming-soon-container">
          <p class="universe-title">{{ 'universe-page.food' | translate }}</p>
          <p class="coming-soon-text">COMING SOON</p>
        </div>
        <img style="padding-right: 18px; max-width: 85px; height: auto;" src="../../../assets/images/universes/food.png"
          width="85px" height="auto">
      </a>
    </li>
  </ul>
</div>