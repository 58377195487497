import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-done-img',
  templateUrl: './done-img.component.html',
  styleUrls: ['./done-img.component.scss']
})
export class DoneImgComponent {
  @Input() title = "You're all done!";
}
