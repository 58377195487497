import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { UniverseService } from '../../services/universe/universe.service';
import { EUniverse } from 'src/app/shared/enums/universe.enum';
import {
  faUpload, faCalendarClock, faCalendarHeart, faHeart, faHouseHeart, faLightbulb, faLightbulbOn,
  faList, faSignsPost, faSquareCheck, faObjectsColumn, faListCheck, faHouseBlank, faMagnifyingGlass, faMicroscope, faGaugeHigh, faCalendarDays, faEllipsisStrokeVertical, faTrophyStar, faChartColumn
} from '@fortawesome/pro-light-svg-icons';
import { faStar, faTire, faSun, faUmbrellaBeach, faGift, faChartUser } from '@fortawesome/pro-light-svg-icons';
import {  faPlus } from '@fortawesome/pro-regular-svg-icons';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AddCalendarSheet } from 'src/app/calendar/components/add-calendar-sheet/add-calendar-sheet.component';
import { MoreSheetComponent } from 'src/app/catalog/more-sheet/more-sheet.component';
import { EToolbarCirkelPosition } from './toolbar-cirkel.enum';
import { CatalogTagService } from '../../services/catalog/catalog-tag.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { Subscription, tap } from 'rxjs';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnDestroy {
  faHouseHeart = faHouseHeart;
  faList = faList;
  faListCheck = faListCheck;
  faPlus = faPlus;
  faLightbulb = faLightbulb;
  faSignsPost = faSignsPost;
  faLightbulbOn = faLightbulbOn;
  faCalendarClock = faCalendarClock;
  faStar = faStar;
  faHouseBlank = faHouseBlank;
  faTire = faTire;
  faSun = faSun;
  faUmbrellaBeach = faUmbrellaBeach;
  faSquareCheck = faSquareCheck;
  faCalendarHeart = faCalendarHeart;
  faMagnifyingGlass = faMagnifyingGlass;
  faHeart = faHeart;
  faObjectsColumn = faObjectsColumn;
  faChartUser = faChartUser;
  faUpload = faUpload;
  faMicroscope = faMicroscope;
  faGaugeHigh = faGaugeHigh;
  faCalendarDays = faCalendarDays;
  faEllipsisStrokeVertical = faEllipsisStrokeVertical;
  faTrophyStar = faTrophyStar;
  faChartColumn = faChartColumn;
  faGift = faGift;

  universe = EUniverse;
  currentUniverse = this.universeService.universe;

  universeSubscription: Subscription | undefined = undefined;
  universeListener$ = toObservable(this.universeService.universe).pipe(
    tap(universe => this.onUniverseChanged(universe!))
  );

  isIphone = this.isIos();
  currentCirkelPosition = signal<EToolbarCirkelPosition>(EToolbarCirkelPosition.PositionZero);
  public enumnavposition = EToolbarCirkelPosition;

  constructor(
    private router: Router,
    private universeService: UniverseService,
    private bottomSheet: MatBottomSheet,
    private catalogTagsService: CatalogTagService
  ) { }

  ngOnInit(): void {
    this.universeSubscription = this.universeListener$.subscribe();
  }

  ngOnDestroy(): void {
    if (this.universeSubscription) {
      this.universeSubscription.unsubscribe();
    }
  }

  private isIos():boolean { 
    const platform = Capacitor.getPlatform(); 
    return platform === 'ios';
  }

  onUniverseChanged(changedUniverse: string) {

    switch (changedUniverse) {
      case 'admin': {
        this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFirst);
        break;
      }
      default: {
        if (window.location.href.includes('home')) {
          this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFirst);
        } else {
          this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionSecond);
        }
        break;
      }
    }
  }

  navigateUniverses() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFifth);
    this.router.navigateByUrl(`universes`);
  }

  navigateHome() {
    const btnHome = document.getElementById('btnHome');
    if (btnHome != null) { btnHome.classList.add('shiftRight'); }

    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFirst);
    this.router.navigateByUrl(`home`);
  }

  // Catalog navigations
  navigateCatalogSearch() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionSecond);
    this.catalogTagsService.selectedItemType.set(null);
    this.router.navigateByUrl(`catalog/catalog`);
  }

  navigateCatalogAdd() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionThird);
    this.router.navigateByUrl(`catalog/products/add`);
  }

  navigateCatalogMore() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFourth);
    this.bottomSheet.open(MoreSheetComponent);
  }

  // Reward navigations
  navigateRewards() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionSecond);
    this.router.navigateByUrl(`reward/wheels`);
  }

  addReward() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionThird);
    this.router.navigateByUrl(`reward/rewards/add`);
  }

  navigateRewardMore() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFourth);
    this.router.navigateByUrl(`reward/more`);
  }

  // To Do navigations
  navigateToDos() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionSecond);
    this.router.navigateByUrl(`to-do/to-dos`);
  }

  addToDo() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionThird);
    this.router.navigateByUrl(`to-do/to-dos/add`);
  }

  navigateToDoMore() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFourth);
    this.router.navigateByUrl(`to-do/more`);
  }

  // Admin navigation
  navigateAdminHome() {
    this.router.navigateByUrl(`admin/home`);
  }

  navigateAdminTest() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFourth);
    this.router.navigateByUrl(`admin/test`);
  }

  navigateAdminMetrics() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionSecond);
    this.router.navigateByUrl(`admin/metrics`);
  }

  navigateAdminPublications() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionThird);
    this.router.navigateByUrl(`admin/publications`);
  }

  // Calendar navigation
  navigateCalendar() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionSecond);
    this.router.navigateByUrl(`calendar/calendar`);
  }

  addCalendarItem() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionThird);
    this.bottomSheet.open(AddCalendarSheet);
  }

  navigateCalendarMore() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFourth);
    this.router.navigateByUrl(`calendar/more`);
  }

  // Women's scoreboard navigation
  navigateWomensScoreboard() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionSecond);
    this.router.navigateByUrl(`scoreboard/womens-scoreboards`);
  }

  addWomensScoreboard() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionThird);
    this.router.navigateByUrl(`scoreboard/womens-scoreboards/add`);
  }

  navigateWomensScoreboardStats() {
    this.currentCirkelPosition.set(EToolbarCirkelPosition.PositionFourth);
    this.router.navigateByUrl(`scoreboard/womens-scoreboards/stats`);
  }
}
